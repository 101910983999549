import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import HeaderBanner from "../components/headerbanner"

const TermsConditions = () => {
  return (
    <div>
      <Helmet>
        <title>Terms & Conditions - GoFloaters</title>
        <meta
          name="description"
          content="These terms and conditions outline the rules and regulations for the use of GoFloaters platform and website."
        ></meta>
        <meta name="keywords" content="terms conditions, gofloaters" />
      </Helmet>
      <Layout>
        <HeaderBanner>
          <h1>Terms & Conditions - GoFloaters</h1>
        </HeaderBanner>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>Welcome to GoFloaters!</p>
              <p>
                These terms and conditions outline the rules and regulations for
                the use of GoFloaters platform and website. GoFloaters platform
                and this website is owned and run by SMBSure Business Solutions
                Pvt Ltd.
              </p>
              <p>
                By accessing this website (and the platform) we assume you
                accept these terms and conditions. Do not continue to use
                GoFloaters if you do not agree to take all of the terms and
                conditions stated on this page.
              </p>
              <p>
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company&rsquo;s terms and
                conditions. "The Company", "Ourselves", "We", "Our" and "Us",
                refers to our Company. "Party", "Parties", or "Us", refers to
                both the Client and ourselves. All terms refer to the offer,
                acceptance and consideration of payment necessary to undertake
                the process of our assistance to the Client in the most
                appropriate manner for the express purpose of meeting the
                Client&rsquo;s needs in respect of provision of the
                Company&rsquo;s stated services, in accordance with and subject
                to, prevailing law of Netherlands. Any use of the above
                terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </p>
              <br />
              <p>
                <strong>Use of GoFloaters Platform</strong>
                <br />
                You understand that except for information, products or services
                clearly indicated as
                <br />
                being supplied by GoFloaters, we do not operate, control, or
                endorse any information,
                <br />
                products or services on the Internet in anyway.{" "}
              </p>
              <ul>
                <li>
                  The availability of a space cannot be confirmed unless booked
                  in advance through the app.
                </li>
                <li>The rates are subject to revision without prior notice.</li>
                <li>
                  Spaces may be blocked for events or may be closed for
                  maintenance. Prior notice shall not be provided for such
                  incidents.
                </li>
                <li>
                  GoFloaters or our space partners cannot be held responsible
                  for the loss or damage of personal property.
                </li>
                <li>
                  You agree to the ethical and legal usage of Internet
                  facilities provided to you and your team. You will be held
                  responsible for any violations.
                </li>
                <li>
                  Unlawful activities are not permitted at any of our partner
                  locations.
                </li>
                <li>
                  For users on the monthly shared workspace plan you are
                  expected to give 1-month prior notice if you intend to vacate
                  the place.
                </li>
                <li>
                  GST (India) or the respective tax laws will be applied on the
                  rates.
                </li>
                <li>
                  You agree that you will not cause or be a disturbance to other
                  users of the place.
                </li>
                <li>
                  Hours of operation of the host will have to be adhered to.
                </li>
                <li>
                  Cancellation and refunds
                  <ul>
                    <li>
                      Daily Coworking Spaces: There are some time limits in
                      place for cancellation. If canceled 6 hours in advance
                      there will be a refund in the form of future credits that
                      can be used for your next booking. If it is canceled
                      within 6 hours of booking then there will be no refunds of
                      any form.
                    </li>
                    <li>
                      GoFloaters Meeting Spaces: Some bookings can be canceled 2
                      days in advance. There will be refunds in the form of
                      future credits that you can use to book future meeting
                      spaces across the city. If it is canceled in under 2 hours
                      then there will be no refunds of any form.
                    </li>
                  </ul>
                </li>
              </ul>
              <br />
              <p>
                <strong>Prohibited Activities</strong>
                <br />
                The content and information on this Website (including, but not
                limited to, messages, data, information, text, music, sound,
                photos, graphics, video, maps, icons, software, code or other
                material), as well as the infrastructure used to provide such
                content and information, is proprietary to GoFloaters. You agree
                not to otherwise modify, copy, distribute, transmit, display,
                perform, reproduce, publish, license, create derivative works
                from, transfer, or sell or re-sell any information, software,
                products, or services obtained from or through this Website.{" "}
              </p>
              <p>Additionally, you agree not to:</p>
              <ol style={{ padding: "0 0 0 35px" }}>
                <li>
                  use this Website or its contents for any commercial purpose
                </li>
                <li>
                  access, monitor or copy any content or information of this
                  Website using any robot, spider, scraper or other automated
                  means or any manual process for any purpose without our
                  express written permission;
                </li>
                <li>
                  violate the restrictions in any robot exclusion headers on
                  this Website or bypass or circumvent other measures employed
                  to prevent or limit access to this Website;
                </li>
                <li>
                  take any action that imposes, or may impose, in our
                  discretion, an unreasonable or disproportionately large load
                  on our infrastructure;
                </li>
                <li>
                  deep-link to any portion of this Website for any purpose
                  without our express written permission; or
                </li>
                <li>
                  "frame", "mirror" or otherwise incorporate any part of this
                  Website into any other website without our prior written
                  authorization.
                </li>
                <li>
                  attempt to modify, translate, adapt, edit, decompile,
                  disassemble, or reverse engineer any software programs used by
                  GoFloaters in connection with the Website or the services.
                </li>
                <li>
                  spam the comments sections or other spaces with unsolicited or
                  unauthorized offers of goods and services, advertising,
                  promotional material, junk mail, spam or chain letters, or
                  inappropriate messages.
                </li>
                <li>
                  use the &ldquo;reservations&rdquo; feature for anything that
                  is outside of legitimate money transmission for spaces to take
                  reservations from customers booking desk and office space
                </li>
                <li>
                  solicit spaces listed on our website(s) with offers to join
                  similar and/or competing service offering
                </li>
              </ol>
              <br />
              <p>
                GoFloaters reserves the right to remove space listings, user
                account and terminate and remove unwarranted content or activity
                from the website without notice. GoFloaters reserves the right
                and may pursue legal action against perpetrators for damages
                caused but these and other prohibited activities.
              </p>
              <br />
              <p>
                <strong>Disputes between users and coworking spaces</strong>
                <br />
                Coworking Spaces are bound to honor the rates, pricing, tours,
                days passes and bookings listed and facilitated through the
                Website. If a coworking space is unable to fulfill any promise
                or reservation, the coworking space and GoFloaters will work
                with the user to reach a mutually satisfactory resolution, which
                may include the issuance of a refund. GoFloaters is under no
                obligation to become involved in disputes between coworking
                spaces and customers, or users and any third party. In the event
                of any dispute, we may provide the coworking space owner's
                contact information to the customer so that the two parties may
                resolve their dispute.
              </p>
              <br />
              <p>
                <strong>Modifications to the GoFloaters  Service</strong>
                <br />
                Features of the GoFloaters may be modified/deleted and may be
                temporarily or permanently suspended at any time, with or
                without notice. GoFloaters will not be liable to you or any
                third party for any modification, suspension, or discontinuance
                of the GoFloaters.
              </p>
              <br />
              <p>
                <strong>You must not</strong>
              </p>
              <ul>
                <li>Republish material from GoFloaters</li>
                <li>Sell, rent or sub-license material from GoFloaters</li>
                <li>Reproduce, duplicate or copy material from GoFloaters</li>
                <li>Redistribute content from GoFloaters</li>
              </ul>{" "}
              <br />
              <p>
                <strong>User Reviews and Comments</strong>
                <br />
                Parts of this website and platform offer an opportunity for
                users to post reviews in certain areas of the website. SMBSure
                Business Solutions Pvt Ltd does not filter, edit, publish or
                review Comments prior to their presence on the website. Comments
                do not reflect the views and opinions of SMBSure Business
                Solutions Pvt Ltd,its agents and/or affiliates. Comments reflect
                the views and opinions of the person who post their views and
                opinions. To the extent permitted by applicable laws, SMBSure
                Business Solutions Pvt Ltd shall not be liable for the Comments
                or for any liability, damages or expenses caused and/or suffered
                as a result of any use of and/or posting of and/or appearance of
                the Comments on this website.
              </p>
              <p>
                SMBSure Business Solutions Pvt Ltd reserves the right to monitor
                all Comments and to remove any Comments which can be considered
                inappropriate, offensive or causes breach of these Terms and
                Conditions.
              </p>{" "}
              <br />
              <p>
                <strong>Your Privacy</strong>
                <br />
                Please read{" "}
                <a href="https://gofloaters.com/privacy-policy/">
                  Privacy Policy
                </a>
              </p>{" "}
              <br />
              <p>
                <strong>Indemnification</strong>
                <br />
                You agree to defend and indemnify GoFloaters and its affiliates
                and any of their officers, directors, employees and agents from
                and against any claims, causes of action, demands, recoveries,
                losses, damages, fines, penalties or other costs or expenses of
                any kind or nature including but not limited to reasonable legal
                and accounting fees, brought by third parties as a result of:
              </p>
              <ul>
                <li>
                  Your breach of this Agreement or the documents referenced
                  herein;
                </li>
                <li>
                  Your violation of any law or the rights of a third party; or
                </li>
                <li>Your use of this Website.</li>
              </ul>{" "}
              <br />
              <p>
                <strong>Governing Law</strong>
                <br />
                These Terms of Service and any separate agreements whereby we
                provide you
                <br />
                Services shall be governed by and construed in accordance with
                the laws of India and
                <br />
                jurisdiction of Chennai, Tamil Nadu.
              </p>{" "}
              <br />
              <p>
                <strong>Cookies</strong>
                <br />
                We employ the use of cookies. By accessing GoFloaters, you
                agreed to use cookies in agreement with the SMBSure Business
                Solutions Pvt Ltd's Privacy Policy.
              </p>
              <p>
                Most interactive websites use cookies to let us retrieve the
                user&rsquo;s details for each visit. Cookies are used by our
                website to enable the functionality of certain areas to make it
                easier for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </p>{" "}
              <br />
              <p>
                <strong>License</strong>
                <br />
                Unless otherwise stated, SMBSure Business Solutions Pvt Ltd
                and/or its licensors own the intellectual property rights for
                all material on GoFloaters. All intellectual property rights are
                reserved. You may access this from GoFloaters for your own
                personal use subjected to restrictions set in these terms and
                conditions.
              </p>{" "}
              <br />
              <p>
                <strong>Customer Service</strong>
                <br />
                If you have any comments or questions regarding these Terms of
                Service, or wish to report any violation of these Terms of
                Service, you may contact us at{" "}
                <a href="mailto:support@gofloaters.com">
                  support@gofloaters.com
                </a>
                .
              </p>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default TermsConditions
